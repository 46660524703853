.app {
  text-align: center;
}

.header {
  text-align: center;
}

.newsc-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  width: 95%;
  border-bottom: 3px solid #ffc000;
}

.newsc-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
} 

.newsc-header-container {
  position: fixed;
  width: 100%;
  height: 200px;
  background-color: white;
}

.newsc-cards-cushion {
  margin-top: 235px;
}

.newsc-title {
  margin-left: 30px;
}

.newsc-input {
  height: 1.5em;
  border: 1px solid gainsboro;
  border-radius: 20px;
  padding: 10px;
}

.newsc-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  padding: 1em;
}

.newsc-single-card {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
}

.newsc-single-card:hover {
  border: 1px #dc2361 solid;
}

.newsc-single-card-flagged-days {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
  background-color: rgb(207, 124, 124);
}

.newsc-single-card-flagged-days:hover {
  border: 1px #dc2361 solid;
}

.newsc-error-msg {
  color: #fd5c63;
  margin-left: 20px;
  margin-top: 0.25em;
}

.newsc-left-cushion {
  margin-left: 30px;
}

.newsc-search-create {
  display: flex;
  align-items: center;
}

.newsc-select {
  width: 50%;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 20px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
  width: fit-content;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.pagination .page-num:hover {
  background-color: #2361dc;
  color: white;
}

.pagination .active-page {
  padding: 8px 15px;
  background-color: #2361dc;
  color: white;
}

.pagination .pagination-disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.submit-btn {
  margin-top: 2em;
  height: 2em;
  width: 50%;
  border-radius: 20px;
  border: none;
  background-color: #2361dc;
  color: white;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: #1246ad;
}

.delete-btn {
  margin-top: 2em;
  height: 2em;
  width: 50%;
  border-radius: 20px;
  border: none;
  background-color: #FF0000;
  color: white;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #C60C30;
}

.newsc-form-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 30px;
}

.newsc-input-search {
  width: 50%;
  margin-left: 1em;
  font-size: 1em;
  padding: 1em;
  border: 1px #ccc solid;
  border-radius: 10em;
}

.automation-link {
  background-color: #dc2361;
  color: white;
  padding: 15px 25px;
  border-radius: 25px;
  text-decoration: none;
  font-size: large;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1em;
  border: 2px solid #dc2361;
}

.automation-link:hover {
  background-color: #be1f54;
}

.top-right-button {
  position: absolute;
  top: 2.6em;
  right: 1.5em;
}

.newsc-loading-symbol-container {
  display: flex;
  margin-top: 150px;
}

.newsc-settings-dropdown-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  position: absolute;
  top: 150px;
}

.newsc-settings-dropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 170px;
  box-shadow: 2px 2px 2px black;
  background-color: white;
}

.newsc-settings-dropdown-item {
  width: 95%;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  height: 25px;
}

.newsc-settings-dropdown-item:hover {
  background-color: #f8f8f8;
}

.newsc-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  margin-left: 30px;
  width: 90%;
}

.newsc-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-card-edit-input {
  width: 90%;
  border: 1px #dcdcdc solid;
  border-radius: 20px;
  padding-left: 0.5em;
}

.newsc-card-edit-btns {
  display: flex;
  width: 150px;
  margin-left: 30px;
  margin-top: 10px;
  justify-content: space-between;
}

.newsc-credential-btn {
  background-color: #2361dc;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

.cards-container {
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
  width: "100%";
}
.cards-vertical-container {
  display: flex;
  flex-direction: column;
  width: 47%;
  align-items: center;
}

.modal-overlay {
  background-color: rgba(255, 255, 255, 0.555);
  width: 100vw;
  height: 200vh;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.modal {
  width: 400px;
  height: 220px;
  background-color: #023047;
  color: white;
  border-radius: 13px;
  padding: 40px;
  z-index: 10;
  opacity: 1;
}

.modal-header {
  height: 50px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modal-heading {
  margin: 0;
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.modal-message {
  padding: 25px;
  font-size: 18px;
  color: white;
  text-align: center;
  font-weight: normal;
}
.modal-btns {
  position: absolute;
  bottom: 20px;
  margin-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirm-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: #023047;
  background: #219ebc;
  transition: all 0.25s ease;
}

.modal-confirm-btn:hover {
  background: #0e819e;
}

.modal-cancel-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: #023047;
  background: #8ecae6;
  transition: all 0.25s ease;
}

.modal-cancel-btn:hover {
  box-shadow: none;
  transform: none;
  background: #5aa3c5;
}
.newsc-tasks-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsc-tasks-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  width: 90%;
}
.newsc-tasks-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-tasks-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}
.newsc-tasks-screenshots-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.newsc-subtitle {
  font-size: 12px;
  color: rgb(109, 109, 109);
  margin: 5px 0px 1px 0px;
}

.newsc-completed-stamp {
  font-size: 12px;
  color: rgb(216, 0, 0);
  margin: 5px 0px 1px 0px;
}

.newsc-edit-btn {
  position: fixed;
  right: 1.5em;
}

.newsc-software-column {
  margin: 0em 2em 0em;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 30vh;
  gap: 0.3vw 3vw;
}

.newsc-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.newsc-message {
  justify-self: center;
  align-self: center;
  font-size: 15px;
  color: rgb(216, 0, 0);
  width: 100%;
  text-align: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-message-container {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.error-message-text {
  margin: 0;
}

.success-message-container {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  margin-bottom: 15px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.success-message-text {
  margin: 0;
}


#login {
  width: 100%;
  height: 60em;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.login-spacing {
  margin-top: 250px;
}

.site__logo {
  margin-bottom: 2rem;
}

.login-input {
  border: 0;
  font: inherit;
}

.login-input::placeholder {
  color: #7e8ba3;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}

.form input {
  outline: 0;
  padding: 0.5rem 1rem;
}

.form * {
  margin: 0.75em;
}

.form input[type="email"],
.form input[type="password"] {
  width: 90%;
}

#sign_in {
  font-size: 2.75rem;
  font-weight: 100;
  margin: 0 0 1rem;
  text-transform: uppercase;
}

.register {
  box-shadow: 0 0 250px #09304e;
  text-align: center;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 25rem;
  width: 100%;
}

.register input {
  border: 1px solid #242c37;
  border-radius: 999px;
  background-color: transparent;
  text-align: center;
}

.register input[type="email"],
.register input[type="password"] {
  background-repeat: no-repeat;
  background-size: 1.5rem;
  background-position: 1rem 50%;
}

.register input[type="email"] {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#242c37"><path d="M256.017 273.436l-205.17-170.029h410.904l-205.734 170.029zm-.034 55.462l-205.983-170.654v250.349h412v-249.94l-206.017 170.245z"/></svg>');
}

.register input[type="password"] {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#242c37"><path d="M195.334 223.333h-50v-62.666c0-61.022 49.645-110.667 110.666-110.667 61.022 0 110.667 49.645 110.667 110.667v62.666h-50v-62.666c0-33.452-27.215-60.667-60.667-60.667-33.451 0-60.666 27.215-60.666 60.667v62.666zm208.666 30v208.667h-296v-208.667h296zm-121 87.667c0-14.912-12.088-27-27-27s-27 12.088-27 27c0 7.811 3.317 14.844 8.619 19.773 4.385 4.075 6.881 9.8 6.881 15.785v22.942h23v-22.941c0-5.989 2.494-11.708 6.881-15.785 5.302-4.93 8.619-11.963 8.619-19.774z"/></svg>');
}

.register button[type="button"] {
  background-image: linear-gradient(160deg, aqua 0%, #155180 100%);
  color: #fff;
  margin-bottom: 3rem;
  width: 50%;
  cursor: pointer;
  border-radius: 1vh;
  padding: 0.5vh;
}

.register button[type="button"]:hover {
  border: solid #155280c2;
}

.header-container {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  z-index: 2;
  border-bottom: #2361dc 4px solid;
  position: fixed;
  width: 100%;
  height: 75px;
  top: 0;
}

.header-logo-container {
  display: flex;
  width: 15%;
  align-items: center;
  text-decoration: none;
  margin-left: 1em;
}

.header-links-container {
  display: flex;
  height: 100%;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
}

.header-link-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2361dc;
  color: white;
  cursor: pointer;
  height: 75px;
  width: 8%;
  text-decoration: none;
}

.header-link-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 8%;
  text-decoration: none;
}

.header-link-inactive:hover {
  background-color: #f5f5f5;
}

.header-underneath-links-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  background-color: #2361dc;
  top: 75px;
  height: 60px;
  width: 100%;
  z-index: 1;
}

.header-underneath-link-active {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  background-color: #0f584e;
  margin-left: 2em;
}

.header-underneath-link-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 60px;
  text-decoration: none;
  color: white;
  margin-left: 2em;
}

.header-dropdown-container {
  position: fixed;
  top: 65px;
  right: 5px;
  height: fit-content;
  width: 150px;
  background-color: white;
  box-shadow: 2px 2px 2px 2px gainsboro;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-dropdown-item {
  width: 100%;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 35px;
}

.header-dropdown-item:hover {
  background-color: #f0f0f0;
}

.header-dropdown-text {
  color: black;
  margin: 0;
}

.header-bars-container {
  margin-right: 120px;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.header-link-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.homepage-icons-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2em;
}

.homepage-icons-column {
  width: 50%;
  border-right: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
}

.homepage-icon {
  display: flex;
  /* width: 100%; */
  height: 100px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdcdc;
  text-decoration: none;
  color: black;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  padding-right: 1em;
  padding-left: 2em;
}

.homepage-icon:hover {
  background-color: #fcfcfc;
}

.homepage-icon-text {
  margin-right: 1em;
  font-size: 1.5em;
}

.notes-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
}

.notes-header-container {
  position: fixed;
  width: 100%;
  height: 200px;
  background-color: white;
}

.notes-header-container-new {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
}

.notes-search-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  flex-wrap: wrap;
  max-height: 400px;
  justify-content: center;
}

.notes-offices-cushion {
  margin-top: 215px;
}

.notes-title {
  margin-left: 20px;
  text-align: left;
}

.search-input {
  width: 50%;
  margin-left: 1em;
  font-size: 1em;
  padding: 1em;
  border: 1px #ccc solid;
  border-radius: 10em;
}

.notes-offices-container {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  padding: 1em;
}

.notes-single-office {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
}

.notes-single-office:hover {
  border: 1px #dc2361 solid;
}

.notes-single-office-flagged {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
  background-color: rgb(207, 124, 124);
}

.notes-single-office-flagged:hover {
  border: 1px #dc2361 solid;
}

.notes-error-msg {
  color: #fd5c63;
  margin-left: 20px;
  margin-top: 0.25em;
}

.notes-left-cushion {
  margin-left: 30px;
}

.search-create {
  display: flex;
  align-items: center;
}

.notes-select {
  width: 50%;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 20px;
}

.notes-search-create {
  display: flex;
  align-items: center;
}

.newsc-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  width: 95%;
  border-bottom: 3px solid #ffc000;
}

.newsc-container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
}

.newsc-header-container {
  position: fixed;
  width: 100%;
  height: 200px;
  background-color: white;
}

.newsc-cards-cushion {
  margin-top: 235px;
}

.newsc-title {
  margin-left: 30px;
}

.newsc-input {
  height: 1.5em;
  border: 1px solid gainsboro;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 2em;
}

.newsc-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  padding: 1em;
}

.newsc-single-card {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
}

.newsc-single-card:hover {
  border: 1px #dc2361 solid;
}

.newsc-single-card-flagged-days {
  display: flex;
  align-items: center;
  width: 20%;
  min-width: fit-content;
  justify-content: flex-start;
  padding: 1em;
  height: 3em;
  cursor: pointer;
  border: 1px #dcdcdc solid;
  text-decoration: none;
  color: black;
  background-color: rgb(207, 124, 124);
}

.newsc-single-card-flagged-days:hover {
  border: 1px #dc2361 solid;
}

.newsc-error-msg {
  color: #fd5c63;
  margin-left: 20px;
  margin-top: 0.25em;
}

.newsc-left-cushion {
  margin-left: 30px;
}

.newsc-search-create {
  display: flex;
  align-items: center;
}

.newsc-select {
  width: 50%;
  margin-top: 1em;
  margin-bottom: 2em;
  margin-left: 20px;
}

.newsc-pagination {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
  width: fit-content;
}

.newsc-pagination .newsc-page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
}

.newsc-pagination .newsc-page-num:hover {
  background-color: #2361dc;
  color: white;
}

.newsc-pagination .newsc-active-page {
  padding: 8px 15px;
  background-color: #2361dc;
  color: white;
}

.newsc-pagination .newsc-disabled {
  cursor: none;
  opacity: 0.5;
  pointer-events: none;
}

.newsc-btn {
  margin-top: 2em;
  height: 2em;
  width: 50%;
  border-radius: 20px;
  border: none;
  background-color: #2361dc;
  color: white;
  cursor: pointer;
}
.newsc-btn:hover {
  background-color: #1246ad;
}

.newsc-form-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 30px;
}

.newsc-input-search {
  width: 50%;
  margin-left: 1em;
  font-size: 1em;
  padding: 1em;
  border: 1px #ccc solid;
  border-radius: 10em;
}

.automation-link {
  background-color: #dc2361;
  color: white;
  padding: 15px 25px;
  border-radius: 25px;
  text-decoration: none;
  font-size: large;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1em;
  border: 2px solid #dc2361;
}

.automation-link:hover {
  background-color: #be1f54;
}

.top-right-button {
  position: absolute;
  top: 2.6em;
  right: 1.5em;
}

.newsc-loading-symbol-container {
  display: flex;
  margin-top: 150px;
}

.newsc-settings-dropdown-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  position: absolute;
  top: 150px;
}

.newsc-settings-dropdown {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 170px;
  box-shadow: 2px 2px 2px black;
  background-color: white;
}

.newsc-settings-dropdown-item {
  width: 95%;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  height: 25px;
}

.newsc-settings-dropdown-item:hover {
  background-color: #f8f8f8;
}

.newsc-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  margin-left: 30px;
  width: 90%;
}

.newsc-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-card-edit-input {
  width: 90%;
  border: 1px #dcdcdc solid;
  border-radius: 20px;
  padding-left: 0.5em;
}

.newsc-card-edit-btns {
  display: flex;
  width: 150px;
  margin-left: 30px;
  margin-top: 10px;
  justify-content: space-between;
}

.newsc-credential-btn {
  background-color: #2361dc;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

.cards-container {
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
  width: "100%";
}
.cards-vertical-container {
  display: flex;
  flex-direction: column;
  width: 47%;
  align-items: center;
}

.modal-overlay {
  background-color: rgba(255, 255, 255, 0.555);
  width: 100vw;
  height: 200vh;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.modal {
  width: 400px;
  height: 220px;
  background-color: #023047;
  color: white;
  border-radius: 13px;
  padding: 40px;
  z-index: 10;
  opacity: 1;
}

.modal-header {
  height: 50px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modal-heading {
  margin: 0;
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.modal-message {
  padding: 25px;
  font-size: 18px;
  color: white;
  text-align: center;
  font-weight: normal;
}
.modal-btns {
  position: absolute;
  bottom: 20px;
  margin-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirm-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: #023047;
  background: #219ebc;
  transition: all 0.25s ease;
}

.modal-confirm-btn:hover {
  background: #0e819e;
}

.modal-cancel-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: #023047;
  background: #8ecae6;
  transition: all 0.25s ease;
}

.modal-cancel-btn:hover {
  box-shadow: none;
  transform: none;
  background: #5aa3c5;
}
.newsc-tasks-table-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsc-tasks-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  width: 90%;
}
.newsc-tasks-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.newsc-tasks-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}
.newsc-tasks-screenshots-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.newsc-subtitle {
  font-size: 12px;
  color: rgb(109, 109, 109);
  margin: 5px 0px 1px 0px;
}

.newsc-completed-stamp {
  font-size: 12px;
  color: rgb(216, 0, 0);
  margin: 5px 0px 1px 0px;
}

.newsc-edit-btn {
  position: fixed;
  right: 1.5em;
}

.newsc-software-column {
  margin: 0em 2em 0em;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 30vh;
  gap: 0.3vw 3vw;
}

.newsc-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.newsc-message {
  justify-self: center;
  align-self: center;
  font-size: 15px;
  color: rgb(216, 0, 0);
  width: 100%;
  text-align: center;
}

.create-office-container {
  width: 70%;
  margin-top: 95px;
  margin-bottom: 3em;
}

.create-office-heading {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.create-office-back-arrow {
  cursor: pointer;
  margin-right: 1em;
}

.offices-container {
  margin-top: 80px;
  display: flex;
  width: "100%";
}

.office-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 5em;
}

.office-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  margin-left: 30px;
  width: 95%;
}

.office-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
  min-width: fit-content;
}

.office-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
  max-width: 200px;
  word-wrap: break-word;
}

.office-table-right-cell-pwd {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
  display: flex;
  justify-content: center;  
}

.office-header-container {
  display: flex;
  width: 95%;
  justify-content: space-between;
  border-bottom: 3px solid #dc2361;
  margin-bottom: 2em;
  align-items: center;
}

.office-computers-container {
  width: 47%;
}

.office-computers-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3em;
  margin-bottom: 2em;
  width: 90%;
  border-bottom: 3px solid #ffc000;
}

.office-computer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin-bottom: 1em;
}
.office-computer-header-tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.office-computer-headertool-options {
  cursor: pointer;
  margin: 1em;
  text-align: center;
}

.office-computer-name {
  margin-top: 0;
  margin-bottom: 0;
}

.office-computer-credentials-header {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  border-bottom: 2px solid red;
}

.office-computer-container {
  display: flex;
  flex-direction: column;
  margin-left: 3em;
}

.office-computer-table {
  border: 1px #dcdcdc solid;
  border-radius: 15px;
  width: 95%;
  margin-bottom: 1em;
}

.office-computer-table-left-cell {
  border-right: 1px #dcdcdc solid;
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.office-computer-table-right-cell {
  border-bottom: 1px #dcdcdc solid;
  padding: 0.5em;
}

.office-computer-credentials-container {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.office-credential-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.office-credential-btn {
  background-color: #2361dc;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

.office-computer-edit-btns {
  display: flex;
  width: 150px;
  justify-content: space-between;
}

.office-credential-edit-btns {
  display: flex;
  width: 150px;
  margin-left: 30px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.office-office-edit-btns {
  display: flex;
  width: 150px;
  margin-left: 30px;
  margin-top: 10px;
  justify-content: space-between;
}

.office-settings-dropdown-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  position: absolute;
  top: 150px;
}

.office-settings-dropdown {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 280px;
  box-shadow: 2px 2px 2px black;
  background-color: white;
}

.office-settings-dropdown-item {
  width: 95%;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  height: 25px;
}

.office-settings-dropdown-item:hover {
  background-color: #f8f8f8;
}

.computer-settings-dropdown-container {
  position: relative;
}

.computer-settings-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  height: 200px;
  box-shadow: 2px 2px 2px black;
  background-color: white;
}

.computer-settings-dropdown-item {
  width: 95%;
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  height: 25px;
}

.computer-settings-dropdown-item:hover {
  background-color: #f8f8f8;
}

.office-make-computer-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin-left: 3em;
}

.office-make-computer-input {
  width: 60%;
  border: 1px #dcdcdc solid;
  border-radius: 20px;
  height: 1.5em;
  padding-left: 0.5em;
  margin-bottom: 10px;
}

.office-computer-edit-input {
  width: 90%;
  border: 1px #dcdcdc solid;
  border-radius: 20px;
  padding-left: 0.5em;
}

.office-loading-symbol-container {
  display: flex;
  margin-top: 150px;
}

.office-edit-system-name {
  width: 55%;
  display: flex;
  align-items: center;
}

.offices-container {
  margin-top: 80px;
  display: flex;
  width: "100%";
}

.admin-three-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.admin-search-results-container {
  margin-top: 20px;
  margin-left: 30px;
}

.admin-loading-text {
  margin-left: 30px;
}

.admin-messsage {
  margin-left: 30px;
  margin-bottom: 20px;
}

.admin-email-job-item {
  display: flex;
  width: 50%;
  border-bottom: 1px solid gray;
  justify-content: space-between;
  align-items: center;
}

.admin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
}

.admin-title {
  margin-left: 30px;
  text-align: left;
}

.admin-links-container {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.admin-link {
  margin-left: 30px;
  font-size: 1.25em;
  text-decoration: none;
  color: black;
  margin-bottom: 1em;
}

.form-label {
  text-align: left;
  margin-bottom: .5em;
}

.user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
}

.user-title {
  margin-left: 30px;
}

.user-links-container {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.user-link {
  margin-left: 30px;
  font-size: 1.25em;
  text-decoration: none;
  color: black;
  margin-bottom: 1em;
}

.hipaa-container {
  display: flex;
  flex-direction: column;
  margin-top: 68px;
  width: 95%;
}

.hipaa-date-container {
  display: flex;
  width: 35%;
}

.hipaa-date-chunk {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  z-index: 0;
}

.hippa-header-container {
  /* position: fixed; */
  width: 100%;
  height: 20px;
  background-color: white;
  margin-left: 20px;
}

.hippa-info-container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hippa-offices-display-btn {
  margin-left: 20px;
  font-weight: bold;
  background-color: #dc2361;
  color: white;
  padding: 15px 25px;
  border-radius: 25px;
  text-decoration: none;
  font-size: medium;
  text-align: center;
  cursor: pointer;
  width: 20%;
}

.reporting-home-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 200px;
}

.reporting-home-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 3em;
}

.reporting-home-tile {
  width: 30%;
  background-color: #f0f0f0;
  border-radius: 20px;
}

.reporting-home-tile-hover {
  width: 30%;
  background-color: #f0f0f0;
  border-radius: 20px;
  transition: 0.3s ease;
}

.reporting-home-tile-hover:hover {
  width: 31%;
  background-color: #e0dddd;
}

.department-logos {
  transition: 0.1s;
  text-decoration: none;
}

.department-logos:hover {
  font-size: large;
}

.gears-container {
  width: 100%;
  height: 200px;
  font-size: 55px;
  padding: 9%;
  position: relative;
  margin: 0px auto;
}

.gear-rotate {
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  margin-top: -1em;
  margin-left: -1em;
  background: #2361dc;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 3s gear-rotate linear infinite;
  -moz-animation: 3s gear-rotate linear infinite;
  animation: 3s gear-rotate linear infinite;
}
.gear-rotate-left {
  margin-top: -2.2em;
  top: 50%;
  width: 2em;
  height: 2em;
  background: #2361dc;
  position: absolute;
  border-radius: 1em;
  -webkit-animation: 3s gear-rotate-left linear infinite;
  -moz-animation: 3s gear-rotate-left linear infinite;
  animation: 3s gear-rotate-left linear infinite;
}

.gear-rotate::before,
.gear-rotate-left::before {
  width: 2.8em;
  height: 2.8em;
  background: -webkit-linear-gradient(
      0deg,
      transparent 39%,
      #2361dc 39%,
      #2361dc 61%,
      transparent 61%
    ),
    -webkit-linear-gradient(60deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%),
    -webkit-linear-gradient(120deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%);
  background: -moz-linear-gradient(
      0deg,
      transparent 39%,
      #2361dc 39%,
      #e8e8e8 61%,
      transparent 61%
    ),
    -moz-linear-gradient(60deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%),
    -moz-linear-gradient(120deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%);
  background: -o-linear-gradient(
      0deg,
      transparent 39%,
      #2361dc 39%,
      #2361dc 61%,
      transparent 61%
    ),
    -o-linear-gradient(60deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%),
    -o-linear-gradient(120deg, transparent 42%, #e8e8e8 42%, #2361dc 58%, transparent
          58%);
  background: -ms-linear-gradient(
      0deg,
      transparent 39%,
      #2361dc 39%,
      #2361dc 61%,
      transparent 61%
    ),
    -ms-linear-gradient(60deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%),
    -ms-linear-gradient(120deg, transparent 42%, #2361dc 42%, #2361dc 58%, transparent
          58%);
  background: linear-gradient(
      0deg,
      transparent 39%,
      #2361dc 39%,
      #2361dc 61%,
      transparent 61%
    ),
    linear-gradient(
      60deg,
      transparent 42%,
      #2361dc 42%,
      #2361dc 58%,
      transparent 58%
    ),
    linear-gradient(
      120deg,
      transparent 42%,
      #2361dc 42%,
      #2361dc 58%,
      transparent 58%
    );
  position: absolute;
  content: "";
  top: -0.4em;
  left: -0.4em;
  border-radius: 1.4em;
}
.gear-rotate::after,
.gear-rotate-left::after {
  width: 1em;
  height: 1em;
  background: #f5f5f5;
  position: absolute;
  content: "";
  top: 0.5em;
  left: 0.5em;
  border-radius: 0.5em;
}

/*
 * Keyframe Animations 
 */

@-webkit-keyframes gear-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-180deg);
  }
}

@-moz-keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes gear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@-webkit-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@-moz-keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

@keyframes gear-rotate-left {
  0% {
    -webkit-transform: rotate(30deg);
  }
  100% {
    -webkit-transform: rotate(210deg);
  }
}

.reporting-query-selector-inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  width: 50%;
  height: 100%;
  border: 1px #2361dc solid;
}

.reporting-query-selector-active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2361dc;
  width: 50%;
  height: 100%;
  border: 1px #2361dc solid;
}

#reporting-query-selector-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

#reporting-query-selector-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.reporting-query-selector-text-inactive {
  color: "white";
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1em;
  color: white;
  background-color: #dc2361;
  display: inline-block;
  padding: 1em;
  width: 75px;
}

.inputfile + label {
  cursor: pointer;
}

.file-name-text {
  border-left: 1px #2361dc solid;
  border-bottom: 1px #2361dc solid;
  border-top: 1px #2361dc solid;
  height: 3em;
  width: 75%;
}

.reporting-tile-space-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.reporting-tile-space-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.reporting-tile-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.reporting-tile-arrow {
  align-self: flex-end;
  margin-right: 1.3em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reporting-user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35%;
}

.reporting-error-message {
  margin-left: 30px;
}

.reporting-top-row-options {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
}

.reporting-top-row-category {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.reporting-stat-table-container {
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
  position: relative;
  flex: 1 1 0;
}

.reporting-ticket-audit-container {
  display: flex;
  flex-direction: row;
  width: 98%;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.ticket-card {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  width: 20%;
  justify-content: flex-start;
  height: fit-content;
  border: 1px #dcdcdc solid;
  color: black;
  margin: 1rem;
  padding: 1rem;
  position: relative;
  transition: 0.1s;
  font-size: large;
}

.ticket-card:hover {
  border: 1px #dc2361 solid;
  font-size: larger;
}

.oracle-header-logo {
  height: 70px;
  width: 135px;
  transition: transform 0.2s ease; /* Smooth transition */
}

.oracle-header-logo:hover {
  transform: scale(1.1); /* Slightly enlarge the element */
}

.copy-tooltip {
  position: absolute;
  top: -30px; /* Adjust this as needed to ensure proper placement */
  left: 50%;
  transform: translateX(-50%);
  background-color: #dc2361; /* Matches the hover border color */
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  animation: fadeInOut 2s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 80vh;
  max-width: 97%;
  width: 97%;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  margin: 1em 0;
  font-family: 'Arial', sans-serif;
  text-align: left;
  table-layout: auto;
}

.styled-table thead tr {
  background-color: #2361DC;
  color: #ffffff;
  text-align: left;
}

.styled-table th, .styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #000;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}